<template>
  <v-container class="pa-0 text-caption">
    <h2 class="text-subtitle-1 font-weight-medium mb-1">
      {{ $t("checkout.orderPending.orderStatus") }}
    </h2>
    <v-chip
      :class="`${chipColor}-gradient rounded-lg justify-center text-caption font-weight-medium mb-3 ekitabu-chip`"
      text-color="textOnPrimary"
    >
      {{ orderStatus }}
    </v-chip>
    <div v-if="orderStatus == STATUSES.FAILED">
      <p>{{ $t("checkout.orderPending.safaricomDeclined") }}:</p>
      <p class="font-weight-bold">
        {{ safaricomMpesaRequest.resultDescription }}
      </p>
    </div>
  </v-container>
</template>

<script>
import BookQueries from "@/graphql/BookQueries";
import gql from "graphql-tag";

export default {
  name: "OrderPending",
  data() {
    return {
      order: null,
    };
  },
  apollo: {
    // TODO: Get the mercure integration working in order to dynamically get updates on the order status
    //  https://github.com/ekitabu-LLC/shop-web/issues/53
    order: {
      query: gql`
        query getOrderStatus($id: ID!, $safaricomMpesaRequestId: String) {
          order(id: $id) {
            id
            confirmed
            safaricomMpesaRequests(id: $safaricomMpesaRequestId) {
              id
              resultCode
              resultDescription
            }
          }
        }
      `,
      variables() {
        return {
          id: this.$route.params.id,
          safaricomMpesaRequestId: this.$route.params.safaricomMpesaRequestId,
        };
      },
      async result({ data }) {
        if (data.order.confirmed) {
          await this.$apollo.query({
            query: BookQueries.getUserBookshelf,
            fetchPolicy: "network-only",
          });
          this.$store.commit("setCurrentCart", null);
          this.$router.push({
            name: "confirmed",
            params: {
              id: this.$route.params.id,
            },
          });
        }
      },
      pollInterval: 1500,
    },
  },
  computed: {
    orderStatus() {
      if (this.safaricomMpesaRequest && this.safaricomMpesaRequest.resultCode) {
        const resultCodeInteger = parseInt(
          this.safaricomMpesaRequest.resultCode
        );
        if (resultCodeInteger === 0) {
          return this.STATUSES.CONFIRMED;
        } else if (resultCodeInteger > 0) {
          return this.STATUSES.FAILED;
        }
      }

      return this.STATUSES.PENDING;
    },
    safaricomMpesaRequest() {
      if (this.order && this.order.safaricomMpesaRequests.length == 1) {
        return this.order.safaricomMpesaRequests[0];
      } else if (this.order && this.order.safaricomMpesaRequests.length > 1) {
        throw new Error(
          "We weren't able to filter out to one safaricomMpesaRequest"
        );
      }
      return null;
    },
    STATUSES() {
      return {
        PENDING: this.$t("checkout.orderPending.statuses.pending")+'...',
        CONFIRMED: this.$t("checkout.orderPending.statuses.confirmed"),
        FAILED: this.$t("checkout.orderPending.statuses.failed"),
      };
    },
    chipColor() {
      if (this.orderStatus == this.STATUSES.PENDING) {
        return "unemphasized";
      } else if (this.orderStatus == this.STATUSES.CONFIRMED) {
        return "tertiary";
      } else if (this.orderStatus == this.STATUSES.FAILED) {
        return "warning";
      }
      return "";
    },
  },
};
</script>
