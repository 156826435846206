import BasketIcon from "@/components/icons/BasketIcon.vue";
import AudiobookIcon from "@/components/icons/AudiobookIcon.vue";
import TriangleRightIcon from "@/components/icons/TriangleRightIcon.vue";
import TriangleUpIcon from "@/components/icons/TriangleUpIcon.vue";
import HeadPhonesIcon from "@/components/icons/HeadPhonesIcon.vue";

export const icons = {
  values: {
    basket: {
      component: BasketIcon,
    },
    audiobook: {
      component: AudiobookIcon,
    },
    triangleRight: {
      component: TriangleRightIcon,
    },
    triangleUp: {
      component: TriangleUpIcon,
    },
    headphones: {
      component: HeadPhonesIcon,
    },
  },
}