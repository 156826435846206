<template>
  <ApolloQuery :query="(gql) => getLocalBooksQuery">
    <template #default="{ result: { error, data }, isLoading }">
      <v-row v-if="!isLoading">
        <book-list-item
          v-for="book in data.localBooks.collection"
          :id="removeLocalPrefix(book.id)"
          :key="book.id"
          :title="book.title"
          :thumbnail-url="book.imageUrl"
          :price="book.price"
          :currency="book.currency"
          :is-owned="book.isOwned"
        />
      </v-row>
      <empty-book-list v-if="!isLoading && (!data || data?.length === 0)" />
      <div v-if="error">
        <apollo-error-alert :error="error" />
      </div>
    </template>
  </ApolloQuery>
</template>
<script>
import gql from "graphql-tag";
import BookListItem from "@/components/bookList/BookListItem";
export default {
  name: "AudioBooks",
  components: {
    BookListItem,
  },
  data() {
    return {
      loadingItemsCount: 12,
    };
  },
  computed: {
    getLocalBooksQuery() {
      return gql`
        query localBooks {
          localBooks {
            __typename
            collection {
              id
              title
              price
              imageUrl
              description
              currency {
                id
                _id
              }
              price
            }
          }
        }
      `;
    },
  },
  methods: {
    removeLocalPrefix(id) {
      return id.replace("local_", "");
    },
  }
};
</script>
