<template>
  <div
    class="input-container"
  >
    <div class="d-flex">
      <ekitabu-checkbox
        v-if="useCheckbox"
        v-model="enabled"
      />
      <label
        v-if="label"
        class="input-label"
        :for="id"
        :style="labelStyle"
      >
        {{ label }}
        <span
          v-if="required"
          class="required"
        >*</span>
      </label>
    </div>
    <validation-provider
      v-slot="{ errors }"
      :rules="rules"
      :name="label"
      mode="aggressive"
    >
      <v-text-field
        :id="id"
        v-bind="$attrs"
        v-model="currentValue"
        v-mask="mask"
        :disabled="!enabled"
        hide-details="auto"
        class="input-field"
        solo
        :error="errors.length > 0"
        :dense="$vuetify.breakpoint.xs"
      />
      <span
        v-for="(error, index) in errors"
        :key="index"
        class="error-text"
      >
        {{ error }}
      </span>
    </validation-provider>
  </div>
</template>

<script>
  import { ValidationProvider } from 'vee-validate'
  import EkitabuCheckbox from '@/components/EkitabuCheckbox';

  export default {
    name: "EkitabuTextInput",
    components: {
      ValidationProvider,
      EkitabuCheckbox,
    },
    props: {
      value: {
        type: [String, Number],
        default: '',
      },
      label: {
        type: String,
        default: null,
      },
      labelStyle: {
        type: String,
        default: null,
      },
      required: {
        type: Boolean,
        default: false,
      },
      id: {
        type: String,
        default: null,
        required: true,
      },
      rules: {
        type: String,
        default: null,
      },
      mask: {
        type: String,
        default: null,
      },
      useCheckbox: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        currentValue: this.value,
        enabled: this.useCheckbox ? false : true,
      }
    },
    watch: {
      currentValue(val) {
        if (val == '') {
          this.$emit("input", null);
        } else {
          this.$emit("input", val);
        }
      },
      value(val) {
        this.currentValue = val;
      },
      enabled(val) {
        if (!val) {
          this.currentValue = null;
        }
      },
    },
  };
</script>