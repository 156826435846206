<template>
  <div>
    <v-btn
      v-bind="$attrs"
      tile
      color="primary"
      elevation="0"
      :small="$vuetify.breakpoint.md || $vuetify.breakpoint.sm"
      :x-small="$vuetify.breakpoint.xs"
      :disabled="!isAvailable"
      @click="openBook()"
    >
      {{
        isAudio ? audioText :
        isAvailable ? $t("bookInfo.openBook") :
        $t("bookInfo.notAvailable")
      }}
    </v-btn>
  </div>
</template>

<script>
import { FORMATS, AUDIO_APP_STORE_LINK, AUDIO_APP_LINK } from "@/utils/Const";
import i18n from '@/i18n';
import { isIosOrMac } from "@/helpers/devices";

export default {
  name: "OpenBookButton",
  props: {
    readUrl: {
      type: String,
      required: false,
      default: null,
    },
    format: {
      type: String,
      required: false,
      default: null,
    },
    audioText: {
      type: String,
      required: false,
      default: i18n.t("bookInfo.listenOnEkitabu"),
    }
  },
  computed: {
    isAudio() {
      return this.format == FORMATS.audio;
    },
    isAvailable() {
      return !!this.readUrl || this.isAudio;
    },
  },
  methods: {
    async openBook() {
      if (this.isAudio) {
        if (isIosOrMac()) {
          window.open(AUDIO_APP_STORE_LINK);
        } else {
          const audioUrl = AUDIO_APP_LINK;
          const fallbackUrl = AUDIO_APP_STORE_LINK;

          const newWindow = window.open(audioUrl);

          setTimeout(() => {
            if (newWindow) {
              newWindow.location.href = fallbackUrl;
            } else {
              window.open(fallbackUrl);
            }
          }, 2000);
        }
      } else {
        window.open(this.readUrl);
      }
    },
  },
};
</script>
