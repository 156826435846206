<template>
  <div class="no-print">
    <v-app-bar
      app
      color="white accent-4"
      dark
      class="main-nav-bar"
      :extension-height="48"
    >
      <router-link
        class="d-none d-sm-block"
        :to="{ name: 'home' }"
      >
        <v-img
          class="logo"
          :contain="true"
          :src="logo"
        >
          <template #placeholder>
            <image-loading />
          </template>
        </v-img>
      </router-link>
      <router-link
        style="height: 90%; width: fit-content"
        class="d-block d-sm-none"
        :to="{ name: 'home' }"
      >
        <img
          class="logo"
          :src="icon"
        >
      </router-link>
      <div
        v-if="$vuetify.breakpoint.mdAndUp"
        class="underline-link-container"
      >
        <router-link :to="{ name: 'home' }">
          {{ $t("menu.shop") }}
        </router-link>
        <router-link
          v-if="$auth.isAuthenticated && $auth.user.email_verified"
          :to="{ name: 'bookshelf' }"
        >
          {{ $t("menu.bookshelf") }}
        </router-link>
        <router-link
          :to="{ name: 'schoolBookLists' }"
        >
          {{ $t("menu.schoolBookLists") }}
        </router-link>
        <router-link
          v-if="$auth.isAuthenticated"
          :to="{ name: 'userProfile' }"
        >
          {{ $t("menu.profile") }}
        </router-link>
        <router-link
          v-if="$auth.isAuthenticated && isAdmin"
          :to="{ name: 'adminDashboard' }"
        >
          {{ $t("menu.admin") }}
        </router-link>
      </div>
      <v-spacer />
      <search-field />
      <cart-badge />
      <div
        v-if="!$auth.loading && !$auth.isAuthenticated"
        class="d-none d-md-flex"
      >
        <login-link
          class="underline-link-container"
          sign-up
        >
          <a class="mr-0">{{ $t("menu.register") }}</a>
        </login-link>
        <login-link class="underline-link-container ml-4">
          <a class="mr-0">{{ $t("menu.login") }}</a>
        </login-link>
      </div>
      <template
        v-if="showReturnBar"
        #extension
      >
        <div class="d-flex flex-grow-1 flex-column">
          <div
            v-if="showReturnBar"
            class="back-extension px-4"
            @click="() => navigateAway()"
          >
            <img
              class="mr-3"
              style="height: 16px; width: auto; position: relative"
              :src="arrowIcon"
            >
            {{ hasHistory ? $t('common.back') : $t($route.meta.returnBarTextLoc) }}
          </div>
        </div>
      </template>
    </v-app-bar>
    <v-progress-linear
      v-if="$auth.loading"
      indeterminate
      color="primary"
    />
  </div>
</template>

<script>
import SearchField from "@/components/SearchField";
import CartBadge from "@/components/navigation/CartBadge";
import LoginLink from "@/components/LoginLink.vue";
import ImageLoading from "@/components/ImageLoading.vue";
import AdminCheckMixin from "@/mixins/AdminCheckMixin";

const logoPath = require("@/assets/img/ekitabuLogo.png");
const iconPath = require("@/assets/img/eKitabu-bug.png");
const arrowIconPath = require("@/assets/img/left-arrow.svg");

export default {
  name: "MainNav",
  components: {
    SearchField,
    CartBadge,
    LoginLink,
    ImageLoading,
  },
  mixins: [AdminCheckMixin],
  data() {
    return {
      logo: logoPath,
      icon: iconPath,
      arrowIcon: arrowIconPath,
    };
  },
  computed: {
    showReturnBar() {
      return this.$route.meta.showReturnBar;
    },
    hasHistory() {
      return window.history.length > 2;
    },
  },
  methods: {
    navigateAway() {
      if (this.hasHistory) {
        this.$router.go(-1);
      }
      else {
        this.$router.push({ name: this.$route.meta.returnBarToRouteName });
      }
    },
  },
};
</script>

<style scoped>
.main-nav-bar .underline-link-container a {
  color: var(--v-primaryText-base);
  text-decoration: none;
  margin-left: 12px;
  margin-right: 12px;
  padding: 5px;
  position: relative;
}
.main-nav-bar .underline-link-container a::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 3px;
  top: 100%;
  left: 0;
  background: var(--v-primary-base);
  transition: transform 0.2s;
  transform: scaleX(0);
  transform-origin: left;
}
.main-nav-bar .underline-link-container a.router-link-exact-active {
  color: var(--v-primaryText-base);
}

.main-nav-bar .underline-link-container a.router-link-exact-active svg {
  fill: var(--v-primaryText-base);
}

.main-nav-bar .underline-link-container a.router-link-exact-active::after {
  transform: scaleX(1);
}
.main-nav-bar .underline-link-container a:hover::after {
  transform: scaleX(1);
}
.main-nav-bar .logo {
  height: 100%;
  max-width: 178px;
  margin-right: 0px;
}

.main-nav-bar {
  margin: 24px !important;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.05);
}

.back-extension {
  display: flex;
  height: 48px;
  align-items: center;
  background-color: var(--v-primary-base);
  color: var(--v-textOnPrimary-base);
  color: var(--v-textOnPrimary-base);
  text-decoration: none;
  cursor: pointer;
}

@media screen and (max-width: 899px) {
  .main-nav-bar .logo {
    margin-right: 22px;
  }

  .main-nav-bar .underline-link-container a {
    font-size: 14px;
  }

  .main-nav-bar {
    margin: 0px !important;
  }
}
@media screen and (max-width: 599px) {
  :deep(.v-toolbar__content),
  .v-toolbar__extension {
    padding: 4px;
  }

  .main-nav-bar .logo {
    height: 100%;
    max-width: 100px;
    width: auto;
    margin: 0 6px;
  }
}
:deep(.v-toolbar__extension) {
  margin: 0;
  padding: 0;
}
</style>
