var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:_vm.containerClasses},[_c('p',[_vm._v(_vm._s(_vm.$t("checkout.freeOrderText")))])]),_c('ApolloMutation',{attrs:{"mutation":(gql) => _vm.placeFreeOrderMutation,"variables":{
      orderId: _vm.orderId,
    },"refetch-queries":() => [
        {
          query: _vm.getBookshelfQuery,
        },
      ]},on:{"done":_vm.onDone},scopedSlots:_vm._u([{key:"default",fn:function({ mutate, loading }){return [_c('PrimaryButton',{staticClass:"text-uppercase",attrs:{"id":"submit","block":"","disabled":loading,"loading":loading},on:{"click":function($event){return mutate()}}},[_vm._v(" "+_vm._s(_vm.$t("checkout.placeFreeOrder"))+" "),_c('v-icon',{staticClass:"ml-2 has-glow",attrs:{"x-small":""}},[_vm._v(" $triangleRight ")])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }