import gql from "graphql-tag";

export default {
  convertPrice: gql`
    query GetCurrencyConversionRate(
      $fromCurrency: String!
      $toCurrency: String!
    ) {
      currencyConversionRate(
        fromCurrency: $fromCurrency
        toCurrency: $toCurrency
      ) {
        id
        rate
        fromCurrency {
          id
          _id
        }
        toCurrency {
          id
          _id
        }
      }
    }
  `,
  getOrder: gql`
    query GetOrder($orderId: ID!) {
      order(id: $orderId) {
        __typename
        id
        _id
        totalLineItemsPrice
        totalDiscount
        subtotalPrice
        totalTax
        totalPrice
        updatedAt
        confirmed
        currency {
          id
          _id
        }
        orderCoupon {
          id
          code
          description
          discount
        }
        orderLines(first: 1000) {
          __typename
          edges {
            node {
              id
              productId
              name
              preTaxPrice
              price
              discount
              authorFirstName
              authorLastName
              baseCurrency {
                id
                _id
              }
            }
          }
        }
      }
    }
  `,
  deleteOrderLine: gql`
    mutation DeleteOrderLine($id: ID!) {
      deleteOrderLine(input: { id: $id }) {
        orderLine {
          id
        }
      }
    }
  `,
  deleteAllOrderLines: gql`
    mutation DeleteAllOrderLines($id: ID!) {
      deleteAllOrderLinesOrder(input: { id: $id }) {
        order {
          id
          orderLines {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  `,
  addItemToOrder: gql`
    mutation AddItemToOrder(
      $itemId: String!
      $orderId: String
      $locale: String!
    ) {
      addItemToOrder(
        input: { itemId: $itemId, orderIri: $orderId, locale: $locale }
      ) {
        order {
          id
          totalLineItemsPrice
          totalDiscount
          subtotalPrice
          totalTax
          totalPrice
          orderLines(first: 1000) {
            edges {
              node {
                id
                productId
                format
                quantity
              }
            }
          }
        }
      }
    }
  `,
  addMultipleItemsToOrder: gql`
    mutation AddMultipleItemsToOrder(
      $itemIds: [String!]!
      $orderIri: String
      $locale: String!
    ) {
      addMultipleItemsToOrder(
        input: { itemIds: $itemIds, orderIri: $orderIri, locale: $locale }
      ) {
        order {
          __typename
          id
          customerId
          orderLines(first: 1000) {
            __typename
            edges {
              node {
                id
                productId
              }
            }
          }
        }
      }
    }
  `,
  addToLibrary: gql`
    mutation AddToLibraryOrder($productId: String!, $locale: String!) {
      addToLibraryOrder(input: { itemId: $productId, locale: $locale }) {
        order {
          id
          confirmed
          orderLines(first: 1000) {
            edges {
              node {
                id
                productId
                name
              }
            }
          }
        }
      }
    }
  `,
  placeMpesaOrder: gql`
    mutation PlaceOrder($input: placeOrderInput!) {
      placeOrder(input: $input) {
        order {
          id
          newSafaricomMpesaRequest {
            id
            resultCode
            updatedAt
            createdAt
          }
          orderLines(first: 1000) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  `,
  placeCreditCardOrder: gql`
    mutation PlaceCreditCardOrder($input: placeCreditCardOrderInput!) {
      placeCreditCardOrder(input: $input) {
        order {
          id
          confirmed
          orderLines(first: 1000) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  `,
  placeCashOrder: gql`
    mutation PlaceCashOrder($input: placeCashOrderInput!) {
      placeCashOrder(input: $input) {
        order {
          id
          confirmed
          orderLines(first: 1000) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  `,
  sendHelpRequest: gql`
    mutation SendHelpRequest($input: sendHelpRequestOrderInput!) {
      sendHelpRequestOrder(input: $input) {
        __typename
      }
    }
  `,
  placeFreeOrder: gql`
    mutation PlaceFreeOrder($orderId: ID!) {
      placeFreeOrder(input: { id: $orderId }) {
        order {
          id
          newSafaricomMpesaRequest {
            id
            resultCode
            updatedAt
            createdAt
          }
          orderLines(first: 1000) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  `,
  claimPaidOrder: gql`
    mutation ClaimPaidOrder {
      claimPaidOrder(input: {}) {
        order {
          id
          _id
          confirmed
          customerEmail
          customerId
          updatedAt
          adminOrder {
            id
            _id
            forUserFullName
            forUserEmail
            forUserPhone
            adminUserId
            adminEmail
            adminFullName
            claimedAt
            createdAt
            updatedAt
          }
          orderLines(first: 1000) {
            edges {
              node {
                id
                productId
                name
                authorFirstName
                authorLastName
              }
            }
          }
        }
      }
    }
  `,
  getUserOrders: gql`
    query GetUserOrders(
      $userId: String!
      $confirmed: Boolean
      $orderByDate: String
      $limit: Int
    ) {
      getUserOrders(
        userId: $userId
        confirmed: $confirmed
        orderByDate: $orderByDate
        limit: $limit
      ) {
        id
        _id
        customerId
        customerEmail
        confirmed
        customerLocale
        updatedAt
        currency {
          id
          _id
        }
        orderCoupon {
          id
          code
        }
        taxRate {
          id
          _id
          rate
        }
        safaricomMpesaRequests {
          id
          merchantRequestId
          resultCode
        }
        dpoPayments {
          id
          transactionReference
          resultCode
        }
        orderLines {
          edges {
            node {
              id
              _id
              name
              publisher
              productId
              basePrice
              baseCurrency {
                id
                _id
              }
              preTaxPriceSet
              priceSet
              discountSet
            }
          }
        }
      }
    }
  `,
  adjustQuantity: gql`
    mutation adjustQuantityOrderLine($input: adjustQuantityOrderLineInput!) {
      adjustQuantityOrderLine(input: $input) {
        orderLine {
          id
          quantity
          forOrder {
            id
            totalLineItemsPrice
            totalDiscount
            subtotalPrice
            totalTax
            totalPrice
          }
        }
      }
    }
  `,
};
