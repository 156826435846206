<template>
  <v-col :class="columnClasses">
    <div class="card-container fill-height pb-5">
      <v-card
        tile
        class="book-card fill-height d-flex flex-column justify-space-between"
        elevation="0"
        @click="$router.push({ name: 'book', params: { id: id } })"
      >
        <v-card-text class="pa-0">
          <v-img
            class="cover-image mb-3 d-flex"
            contain
            :src="thumbnailUrl"
          >
            <template #placeholder>
              <image-loading />
            </template>
            <v-tooltip
              v-if="isOwned"
              top
            >
              <template #activator="{ on }">
                <v-icon
                  class="owned-icon pa-1 ma-0"
                  color="background"
                  v-on="on"
                >
                  mdi-bookshelf
                </v-icon>
              </template>
              <span>{{ $t("bookshelf.inBookshelf") }}</span>
            </v-tooltip>
          </v-img>
          <div class="book-title mb-0 mb-sm-1">
            {{ decodeHtml(title) }}
          </div>
          <price
            v-if="price != null && !isOwned"
            class="book-price"
            :book-price="Number(price)"
            :base-currency="currency._id"
            include-tax
            :should-round="true"
          />
          <div
            v-else-if="isOwned"
            style="font-size: 12px"
          >
            {{ $t("bookshelf.owned") }}
          </div>
        </v-card-text>
        <div
          v-if="showBuyNow"
          class="actions-container"
        >
          <div v-if="!isOwned">
            <add-to-cart-button
              v-if="!isOwned"
              :id="id"
              :simplified-id="noIriId"
              :query-loading="isOwnedLoading"
              block
              proceed-to-checkout
              class="mt-2"
              :add-to-cart-text="$t('shop.buyNow')"
            />
            <add-to-cart-button
              :id="id"
              :simplified-id="noIriId"
              :query-loading="isOwnedLoading"
              block
              class="mt-2"
            />
          </div>
          <open-book-button
            v-else-if="isOwned"
            class="mt-2"
            :read-url="readUrl"
            :format="format"
            block
            :audio-text="$t('bookInfo.listenShort')"
          />
        </div>
      </v-card>
      <slot />
    </div>
  </v-col>
</template>

<script>
import Price from "@/components/Price";
import { decodeHtml } from "@/helpers/generalFunctions";
import ImageLoading from "@/components/ImageLoading";
import AddToCartButton from "@/views/checkout/AddToCartButton.vue";
import { removeIriId } from "@/iris";
import OpenBookButton from "@/components/OpenBookButton";

var images = require.context("@/assets/img/", false, /\.jpeg$/);

export default {
  components: {
    Price,
    ImageLoading,
    AddToCartButton,
    OpenBookButton,
  },
  props: {
    price: {
      type: [String, Number],
      required: false,
      default: null,
    },
    id: {
      type: String,
      required: true,
    },
    thumbnailUrl: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    currency: {
      type: Object,
      required: false,
      default: null,
      validator: (value) => {
        return value && value._id;
      },
    },
    columnClasses: {
      type: String,
      default: "col-4 col-sm-3 col-md-2 pa-1 pa-sm-2 pa-md-3",
    },
    isOwned: {
      type: Boolean,
      required: false,
      default: false,
    },
    isOwnedLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    showBuyNow: {
      type: Boolean,
      required: false,
      default: false,
    },
    format: {
      type: String,
      required: false,
      default: null,
    },
    readUrl: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    noIriId() {
      return removeIriId(this.id);
    },
  },
  methods: {
    imgUrl: function (path) {
      return images("./" + path);
    },
    decodeHtml(text) {
      return decodeHtml(text);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";

.book-card {
  max-width: 250px;
  margin: 0 auto;
}

.cover-image {
  width: 100%;
  aspect-ratio: 1/1.6;
}

.book-title {
  font-size: 0.875rem;
  font-weight: 500;
}

.book-price {
  font-size: 0.75rem;
}

.card-container {
  position: relative;
}

.owned-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-color: var(--v-secondary-base);
}

.actions-container {
  height: 88px;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .actions-container {
    height: 72px;
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .book-title {
    font-size: 0.75rem;
  }

  .book-price {
    font-size: 0.625rem;
  }

  .actions-container {
    height: 56px;
  }
}
</style>
