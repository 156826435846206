<template>
  <v-app>
    <main-nav />
    <error-snackbar
      v-model="errorSnackbar"
      :error-label="$t('serverError')"
      :error-message="errorMessage"
    />
    <get-user-region />
    <v-main
      id="app-content-container"
    >
      <v-breadcrumbs
        v-if="breadCrumbs"
        class="bread-crumbs pa-0 no-print"
        :items="breadCrumbs"
        divider=">"
        large
      />
      <auth-loading v-if="$auth.loading" />
      <router-view v-else />
    </v-main>
    <ekitabu-footer />
    <v-bottom-navigation
      v-if="$vuetify.breakpoint.smAndDown && showMobileNav"
      class="align-center no-print"
      color="primary"
      app
      grow
    >
      <v-btn
        :to="{ name: 'home' }"
        class="footer-buttons"
      >
        <span>{{ $t("menu.shop") }}</span>
      </v-btn>
      <v-btn
        v-if="$auth.isAuthenticated && $auth.user.email_verified"
        :to="{ name: 'bookshelf' }"
        class="footer-buttons"
      >
        <span>{{ $t("menu.bookshelf") }}</span>
      </v-btn>
      <v-btn
        v-if="$auth.isAuthenticated"
        :to="{ name: 'userProfile' }"
        class="footer-buttons"
      >
        <span>{{ $t("menu.profile") }}</span>
      </v-btn>
      <v-btn
        :to="{ name: 'schoolBookLists' }"
        class="footer-buttons"
      >
        <span
          style="line-clamp: 2;"
        >
          {{ $t("menu.schoolBookLists") }}
        </span>
      </v-btn>
      <login-link
        v-if="!$auth.isAuthenticated"
        class="v-btn"
      >
        <v-btn class="footer-buttons">
          <span>{{ $t("menu.login") }}</span>
        </v-btn>
      </login-link>
      <login-link
        v-if="!$auth.isAuthenticated"
        sign-up
        class="v-btn"
      >
        <v-btn class="footer-buttons">
          <span>{{ $t("menu.register") }}</span>
        </v-btn>
      </login-link>
    </v-bottom-navigation>
    <claimed-order-modal />
  </v-app>
</template>

<script>
import MainNav from "./components/navigation/MainNav";
import ErrorSnackbar from "@/components/ErrorSnackbar";
import { mapGetters, mapMutations } from "vuex";
import AuthLoading from "@/components/AuthLoading";
import LoginLink from "@/components/LoginLink";
import EkitabuFooter from "@/components/footer/EkitabuFooter";
import GetUserRegion from "@/components/GetUserRegion";
import ClaimedOrderModal from "./components/ClaimedOrderModal";

export default {
  name: "App",
  components: {
    MainNav,
    ErrorSnackbar,
    AuthLoading,
    LoginLink,
    EkitabuFooter,
    GetUserRegion,
    ClaimedOrderModal,
  },
  data() {
    return {
      errorSnackbar: false,
    };
  },
  computed: {
    ...mapGetters("serverError", ["errorCode", "errorMessage"]),
    ...mapGetters("siteSettings", ["showMobileNav"]),
    breadCrumbs() {
      if (typeof this.$route.meta.breadCrumbs === "function") {
        return this.$route.meta.breadCrumbs.call(this, this.$route);
      }
      return this.$route.meta.breadCrumbs;
    },
  },
  watch: {
    errorSnackbar(newValue, oldValue) {
      if (newValue == false && oldValue == true) {
        setTimeout(() => {
          this.setErrorCode("");
          this.setErrorMessage("");
        }, 300);
      }
    },
    errorMessage(newErrorMessage) {
      if (newErrorMessage) {
        this.errorSnackbar = true;
      }
    },
    errorCode(newErrorCode) {
      if (newErrorCode) {
        this.errorSnackbar = true;
      }
    },
  },
  methods: {
    ...mapMutations("serverError", ["setErrorCode", "setErrorMessage"]),
  },
};
</script>

<style scoped>
:deep(.v-text-field > .v-input__control > .v-input__slot) {
  margin-bottom: 0;
}

:deep(.v-text-field > .v-input__control > .v-input__slot:before) {
  border-style: none;
}

.copyright-text-container {
  min-width: 200px;
}

.copyright-text-container {
  text-align: right;
}

#app-content-container {
  margin-top: 40px;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 76px;
}

.bread-crumbs {
  height: 30px;
}

::v-deep .v-breadcrumbs.bread-crumbs .v-breadcrumbs__item,
::v-deep .v-breadcrumbs.bread-crumbs .v-breadcrumbs__divider {
  color: var(--v-primaryText-base);
}

::v-deep .v-breadcrumbs.bread-crumbs .v-breadcrumbs__item:hover {
  text-decoration: underline;
}

::v-deep .v-breadcrumbs.bread-crumbs > li:last-of-type > .v-breadcrumbs__item{
  color: var(--v-primary-base);
}

.v-btn.footer-buttons {
  height: 100%;
}

@media screen and (max-width: 899px) {
  #app-content-container {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media screen and (max-width: 599px) {
  #app-content-container {
    margin-top: 12px;
  }
}
</style>
