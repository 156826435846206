<template>
  <ApolloMutation
    :mutation="(gql) => placeOrderQuery"
    :variables="{
      input: {
        id: orderId,
        phoneNumber: prefixedPhoneNumber,
        alternativePhoneNumber: shippingInfo?.altPhoneNumber,
        placeId: shippingInfo?.placeId,
        addressDetails: shippingInfo?.addressDetails,
        schoolId: schoolInfo?.schoolId,
        studentName: schoolInfo?.studentName,
        studentClass: schoolInfo?.studentClass,
        ...(isAdminOrder && {
          isForPhone: true,
          forFullName: adminOrderDetails?.forName,
          forEmail: adminOrderDetails?.forEmail,
          eventId: adminOrderDetails?.eventId,
        })
      },
    }"
    @done="onDone"
    @error="() => { loading = false; }"
  >
    <template #default="{ mutate }">
      <PrimaryButton
        id="submit"
        class="text-uppercase"
        block
        :disabled="loading || invalid"
        :loading="loading"
        @click="() => {
          loading = true;
          mutate()
        }"
      >
        {{ $t("checkout.form.placeOrder") }}
        <v-icon
          class="ml-2 has-glow"
          x-small
        >
          $triangleRight
        </v-icon>
      </PrimaryButton>
    </template>
  </ApolloMutation>
</template>

<script>
  import OrderQueries from "@/graphql/OrderQueries";
  import UserMutations from "@/graphql/UserMutations";
  import PrimaryButton from "@/components/buttons/PrimaryButton.vue";

  export default ({
    name: "PlaceOrderButton",
    components: {
      PrimaryButton,
    },
    props: {
      prefixedPhoneNumber: {
        type: String,
        default: '',
      },
      invalid: {
        type: Boolean,
        default: false,
      },
      shouldSaveAccountInfo: {
        type: Boolean,
        default: false,
      },
      shippingInfo: {
        type: Object,
        default: null,
      },
      schoolInfo: {
        type: Object,
        default: null,
      },
      isPickupOrder: {
        type: Boolean,
        default: false,
      },
      isAdminOrder: {
        type: Boolean,
        default: false,
      },
      adminOrderDetails: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        orderId: this.$store.state.cart.currentCart,
        loading: false,
      };
    },
    computed: {
      placeOrderQuery() {
        return OrderQueries.placeMpesaOrder;
      },
    },
    methods: {
      async updatePhoneNumber() {
        if (this.shouldSaveAccountInfo) {
          await this.$apollo.mutate({
            mutation: UserMutations.updateProfileInfo,
            variables: {
              input: {
                phoneNumber: this.prefixedPhoneNumber,
              },
            },
          })
        }
      },
      onDone (data) {
        let id = data.data.placeOrder.order.id;
        let safaricomMpesaRequestId = data.data.placeOrder.order.newSafaricomMpesaRequest.id;

        this.updatePhoneNumber().then(() => {
          if (id) {
            this.loading = false;
            this.$router.push({
              name: "orderPending",
              params: {
                id: id,
                safaricomMpesaRequestId: safaricomMpesaRequestId,
              },
            });
          }
        }).catch(() => {
          this.loading = false;
        })
      },
    },
  });
</script>